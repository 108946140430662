import { FeatureFlagSetup, useExtractFeatureFlagOverrides } from '@sortlist-frontend/feature-flags';
import { isIgnoredError, Monitor } from '@sortlist-frontend/mlm';
import { getAbTestData, PublicApiTrackingEvents, RequiredTrackProps } from '@sortlist-frontend/tracking';
import { getStorage, isString } from '@sortlist-frontend/utils';
import { useMutation } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';

import { api } from '_core/api/api-nextjs-proxy';
import { usePublicAppContext } from '_core/context/public-app-context';

export const urls = {
  track: `/api/proxy/log`,
};

const getSafeJson = (json: string | false | null) => {
  if (!json) return json;

  try {
    return JSON.parse(json);
  } catch {
    return json;
  }
};

export const trackRepo = {
  track: async (
    data: PublicApiTrackingEvents,
    /**
     * @deprecated AB test data is extracted from cookies now
     */
    featureFlagsConfig: FeatureFlagSetup,
    config?: AxiosRequestConfig,
  ): Promise<any> => {
    const anonymousId = getSafeJson(getStorage('ajs_anonymous_id'));
    const loggedUserId = getSafeJson(getStorage('ajs_user_id'));
    const timestamp = new Date().toISOString();

    const abTests = getAbTestData();

    const extraTrackProps: RequiredTrackProps = {
      user_id: isString(loggedUserId) ? loggedUserId : isString(anonymousId) ? anonymousId : '',
      user_type: loggedUserId != null ? 'logged' : anonymousId != null ? 'anonymous' : undefined,
      ab_tests: abTests ?? {},
      sent_at: timestamp,
      app: 'appPublic',
    };

    try {
      const trackResponse = await api.post(
        urls.track,
        {
          data: { attributes: { ...data, ...extraTrackProps } },
        },
        config,
      );

      return trackResponse?.data;
    } catch (e) {
      Monitor.captureException('trackRepo error', {
        extra: { error: e, extraTrackProps, data },
      });
    }
  },
};

/**
 * @deprecated use the `track` function from the hook at `import { usePublicApiTracker } from '_core/hooks/use-tracker'`
 */
export function useTrackWithoutContext(originUrl: string, requestUrl: string) {
  const experimentConfig = useExtractFeatureFlagOverrides({}, requestUrl);
  return {
    mutateAsync: (data: PublicApiTrackingEvents) => trackRepo.track(data, experimentConfig, { baseURL: originUrl }),
  };
}

/**
 * @deprecated use the `track` function from the hook at `import { usePublicApiTracker } from '_core/hooks/use-tracker'`
 */
export function useTrack() {
  const { domainInfo, canonical } = usePublicAppContext();
  const baseURL = domainInfo?.getOriginUrl() ?? '';
  const experimentConfig = useExtractFeatureFlagOverrides({}, canonical);

  return useMutation({
    mutationFn: (data: PublicApiTrackingEvents) => trackRepo.track(data, experimentConfig, { baseURL }),
  });
}
